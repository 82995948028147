.survey-form-title {
  font-size:24px;
}

.survey-form-label {
  margin-bottom:20px;
}

.survey-element {
  margin-bottom:60px;
}

.survey-element-img {
  width:100%;
  /* border:1px solid #eee; */
  margin-bottom:30px;
}
.survey-element-img img {
  border-radius:10px;
}

.survey-element-title {
  margin-top:10px;
  font-size:24px;
  font-weight: bold;
}

.survey-element-subtitle {
  font-weight: 100;
  color: #999;
  font-size:13px;
  margin-bottom:15px;
  margin-top:3px;
}

.survey-element-comment > ion-textarea {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;  
  padding-left:10px;
  padding-right:10px;
}

.element-separator {
  border-bottom: 1px dotted #ddd;
  margin-top:30px;
  margin-bottom:60px;
  margin-left:auto;
  margin-right:auto;
}

.survey-title-separator {
  border-bottom:1px solid #ddd;
  margin-bottom:30px;
  margin-top:10px;
}

.survey-criteria-row {
  margin-bottom:30px;
}

.survey-section {
  margin-bottom:50px;
}

.survey-section-title {
  font-size:20px;
  margin-bottom:30px;
  padding-bottom:5px;
  border-bottom:1px solid #eee;
}