.short-code-block {
  border:5px solid #ddd;
  padding:8px;
  text-align: center;
  max-width:200px;
  border-radius:30px;
  margin-left:auto;
  margin-right:auto;
  margin-top:20px;
  margin-bottom:20px;
  font-family: 'Courier New', Courier, monospace !important;
  font-weight: bold;
  font-size:30px;
  color:#007bc0;
}