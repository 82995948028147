.login-field, .single-line-text-input {
  border-bottom:1px solid #ddd;
  margin-bottom:15px;
  background-color:#fafafa;
  border-radius:5px;
}

.login-field input, .single-line-text-input input {
  padding-left:8px !important;
  padding-right:8px !important;
}

ion-input {
  border: 2px solid #ddd;
  border-radius:5px;
  background-color: #ffffff !important;
}

ion-input input {
  padding-left:10px !important;
  padding-right:10px !important;
}

ion-textarea {
  border: 2px solid #ddd !important;
  border-radius:5px;
  background-color: #ffffff !important;
}

ion-item {
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 10px;
  --padding-top: 0px;
}

ion-slides {
  height: 100%;
}

ion-item-divider {
  --padding-start: 10px;
}

ion-fab-button {
  margin-top:10px;
  margin-right:5px;
}

.no-border-please > ion-toolbar {
  --border-width: 0px !important;
}

.qr-scanner ion-content::part(background) { 
  background: transparent;
}

ion-segment-button {
  font-size:14px;
}

ion-item-option::part(native) {
  min-width: 50px;
}

ion-label {
  white-space: normal !important;
}

ion-select.section-selector {
  --padding-start:0px;
  font-size:20px;
}

@media only screen and (min-width: 1000px) {
  body {
    background:gray;
  }
  ion-app {
    max-width: 500px;
    margin-left:auto;
    margin-right:auto;
    background-color:white;
  }
  #root > ion-app {
    margin-left:auto;
    margin-right:auto;
  }
}